<template>
    <div class="itemArticle" v-if="article">
        <div class="cover">
            <div class="filler"></div>
            <Image :image="article.cover" :width="600" :height="400" :mobileWidth="824" :mobileHeight="550" imageClass="cover" />
        </div>
        <div class="info">
            <div class="helvetica title3">{{ article.title }}</div>
            <div class="summary">{{ article.summary }}</div>
        </div>
        <div class="readLink"><span class="title6 helvetica">Read</span> →</div>

        <NuxtLink class="overlink" :to="{ name: 'news-slug', params: { slug: article.url } }" :aria-label="article.title"></NuxtLink>
    </div>
</template>

<script lang="ts" setup>
import { NTag } from 'naive-ui';
const props = defineProps<{ article: any }>()

const articleDate = computed(() => {
    if (props.article && props.article.article_date) return new Date(props.article.article_date)
    else return null
})
</script>

<style lang="scss" scoped>
.itemArticle {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--size-gutter-default);

    .cover {
        width: 100%;
        background-color: #fafafa;
        position: relative;

        .filler {
            padding-top: 66.667%;
        }

        &:deep(.responsivePicture) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        gap: var(--size-gutter-small);

        .title5 {
            text-wrap: balance;
        }

        .date {
            display: flex;
            gap: var(--size-gutter-small);
            color: var(--color-primary);
        }

        .tags {
            display: flex;
            gap: var(--size-gutter-small);
            flex-wrap: wrap;
        }
    }

    .readLink {
        color: var(--color-primary);
    }
}
</style>
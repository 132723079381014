<template>
    <main v-if="data && data.page">
        <div class="splashCont">
            <div class="splash">
                <CopyrightedImage :image="data.page.splash_images[splashImageIndex].cover" :width="1200" :cover="true" />
            </div>
            <div class="introduction">
                <div class="helvetica title3">{{ data.page.introduction }}</div>
                <div>
                    <NuxtLink class="readLink" to="/about/who-we-are"><span class="title6 helvetica">About EIC</span> →</NuxtLink>
                </div>
            </div>
        </div>

        <div class="nextConferenceCont">
            <h2 class="baron headerText">Conference</h2>
            <ItemConferenceNext :conference="data.conference" />
            <div class="conferences">
                <template v-for="conference in data.conferences" :key="conference.url">
                    <ItemConference :conference="conference" />
                </template>
            </div>
            <div class="conferencesLink">
                <NuxtLink class="readLink" :to="{ name: 'conferences' }"><span class="title6 helvetica">Past conferences</span> →</NuxtLink>
            </div>
        </div>

        <div class="highlightsCont" v-if="data.page.highlights && data.page.highlights.length > 0">
            <h2 class="baron headerText">Highlights</h2>
            <div class="grid">
                <template v-for="highlight in data.page.highlights">
                    <ItemHighlightArticle :article="highlight.item" v-if="highlight.collection == 'articles'" />
                </template>
            </div>
        </div>

        <BlockAdvocacy :themes="data.themes" />
        <BlockArticles title="articles" :articles="data.articles" />
        <div class="buttonCont">
            <NuxtLink :to="{ name: 'news' }" :custom="true">
                <template v-slot="{ navigate }">
                    <NButton type="secondary" size="large" @click="navigate">More articles</NButton>
                </template>
            </NuxtLink>
        </div>

        <WebsiteFooter :partners="true" />
    </main>
</template>

<script lang="ts" setup>
import { NButton } from 'naive-ui';

const { getHomepage } = usePages();
const directusUrl = useDirectusUrl()
const randomIndex = useRandomIndex()

const { data } = useAsyncData('homepage', () => getHomepage());

const splashImageIndex = computed(() => Math.floor(randomIndex.value * data.value?.page.splash_images.length));

useSeoMeta({
    title: () => data.value?.page?.title,
    ogTitle: () => data.value?.page?.title,
    twitterTitle: () => data.value?.page?.title,

    description: () => data.value?.page.introduction,
    ogDescription: () => data.value?.page.introduction,
    twitterDescription: () => data.value?.page.introduction,

    ogImage: () => data.value?.page?.cover?.id ? directusUrl + '/assets/' + data.value?.page.cover.id : undefined,
})
</script>

<style lang="scss" scoped>
main {
    .splashCont {
        .splash {
            position: relative;
            height: calc(100vh - var(--size-gutter-sections) - (var(--text-line-title3) * 1.6));
            overflow: hidden;

            &:deep(img) {
                object-position: top center;
            }
        }

        .introduction {
            padding: var(--size-gutter-sections) var(--size-margin-page);

            display: flex;
            flex-direction: column;
            gap: var(--size-gutter-default);

            .readLink {
                color: var(--color-primary);
                text-decoration: none;
            }
        }
    }

    .highlightsCont {
        margin: 0 var(--size-margin-page);
        padding-bottom: var(--size-gutter-sections);

        display: flex;
        flex-direction: column;
        gap: var(--size-gutter-double);

        h2 {
            border-top: 0.5px solid var(--color-primary);
            font-weight: normal;
            color: var(--color-primary);
            padding-top: var(--size-margin-page);
        }

        .grid {
            display: grid;
            grid-template-columns: 5fr 5fr 2fr;
            gap: var(--size-gutter-double) var(--size-margin-page);
        }
    }

    .nextConferenceCont {
        margin: 0 var(--size-margin-page);
        padding-bottom: var(--size-gutter-sections);

        h2 {
            border-top: 0.5px solid var(--color-primary);
            font-weight: normal;
            color: var(--color-primary);
            padding-top: var(--size-margin-page);
            padding-bottom: var(--size-gutter-double);
        }

        .conferences {
            margin-top: var(--size-gutter-large);

            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            gap: var(--size-gutter-double) var(--size-margin-page);
        }

        .conferencesLink {
            margin-top: var(--size-gutter-double);
            color: var(--color-primary);
        }
    }

    .buttonCont {
        padding: var(--size-gutter-double) var(--size-margin-page) 0 var(--size-margin-page);
    }
}

@media screen and (max-width: 1280px) {
    main {
        .nextConferenceCont {
            .conferences {
                grid-template-columns: repeat(4, minmax(0, 1fr));

                &:deep(.conference) {
                    &:nth-of-type(5) {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    main {
        .nextConferenceCont {
            .conferences {
                grid-template-columns: repeat(3, minmax(0, 1fr));

                &:deep(.conference) {
                    &:nth-of-type(4) {
                        display: none;
                    }
                }
            }
        }

        .highlightsCont {
            .grid {
                grid-template-columns: 2fr 1fr;

                &:deep(.itemArticle) {
                    grid-column: 1;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    main {
        .nextConferenceCont {
            .conferences {
                display: flex;
                flex-direction: row;
                gap: 0;

                overflow-x: auto;
                overflow-y: hidden;
                scroll-snap-type: x mandatory;

                scroll-behavior: smooth;
                -webkit-overflow-scrolling: touch;

                padding-bottom: var(--size-gutter-sections);
                padding-right: 100px;

                margin-left: calc(0px - var(--size-margin-page));
                margin-right: calc(0px - var(--size-margin-page));

                &:deep(.conference) {
                    scroll-snap-align: start;
                    scroll-snap-align: start;
                    width: calc(100vw - 100px);
                    flex-shrink: 0;
                    border-left: var(--size-margin-page) solid white;
                }
            }
        }

        .highlightsCont {
            .grid {
                grid-template-columns: 1fr;
                gap: var(--size-gutter-large) 0;
            }
        }
    }
}
</style>